import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { CompleteLayout } from '../../components/forms/CompleteLayout';

export const Head = () => (
  <HeadInner
    path='/partner-program-complete/'
    title='ご予約ありがとうございます'
    noindex
  />
);

const CompletePage = () => {
  const links = [
    { name: 'ご予約ありがとうございます', path: '/partner-program-complete/' },
  ];

  return (
    <Layout
      header='simple'
      footer='simple'
      background='simple'
      isBottomMenu={false}
      links={links}
    >
      <CompleteLayout>
        <h2>お打ち合わせの予約が完了しました</h2>
      </CompleteLayout>
    </Layout>
  );
};

export default CompletePage;
